import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/contact-form'

const Page = ({ location }) => (
	<Layout>
		<SEO title="Contato" />
		<div
			className="uk-card uk-card-default uk-padding-remove-top uk-padding-remove-horizontal uk-card-body"
			data-uk-grid
		>
			<div className="uk-width-1-1 uk-padding-remove">
				<div id="map">
					<iframe
						title="mapa"
						width="100%"
						height="450"
						frameBorder="0"
						style={{ border: 0 }}
						src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJzd9Vc9FhmQARWJR3ingd2Yg&key=AIzaSyCHU_sIn81Z7rkwVzV-SaVPLTIjuurSg3Y"
						allowFullScreen
					></iframe>
				</div>
			</div>
			<div className="uk-width-1-1" data-uk-grid>
				<div className="uk-width-1-1@s uk-width-1-2@m">
					<ul className="uk-list uk-link-text">
						<li>
							<span
								className="uk-margin-xsmall-right"
								uk-icon="receiver"
							></span>
							<a href="tel:+5502134629154">(21) 3462 9154</a>
						</li>
						<li>
							<span className="uk-margin-xsmall-right" uk-icon="phone"></span>
							<a href="tel:+55021984799154">(21) 98479 9154</a>
						</li>
					</ul>
				</div>
				<div className="uk-width-1-1@s uk-width-1-2@m">
					<ul className="uk-list uk-link-text">
						<li>
							<span className="uk-margin-xsmall-right" uk-icon="mail"></span>
							<a href="mailto:istm@istm.com.br">istm@istm.com.br</a>
						</li>
						<li>
							<span
								className="uk-margin-xsmall-right"
								uk-icon="location"
							></span>
							<a href="https://goo.gl/maps/fLJJzFQRGCx">
								Rua Pacaembu, 62 - Realengo RJ
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div className="uk-width-1-1">
				<h3>Contato</h3>
				<p>Entre em contato conosco e responderemos o mais rápido possível!</p>
			</div>
			<div className="uk-width-1-1 uk-padding uk-padding-remove-top">
				<ContactForm
					targetProduct={
						location.state && location.state.targetProduct
							? location.state.targetProduct
							: null
					}
				/>
			</div>
		</div>
	</Layout>
)
export default Page
