import React, { useState } from 'react'
import MaskedInput from 'react-text-mask'
import { sendEmail } from '../utils/email'

const StatusIconSending = () => (
	<span className="uk-padding-small" data-uk-spinner></span>
)

const StatusIconSuccess = () => (
	<span className="uk-padding-small" uk-icon="check"></span>
)

const StatusIconFailed = () => (
	<span className="uk-padding-small" uk-icon="close"></span>
)

const ContactForm = ({ targetProduct }) => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [firm, setFirm] = useState('')
	const [phone, setPhone] = useState('')
	const [subject, setSubject] = useState('')
	const [message, setMessage] = useState('')
	const [submitButtonText, setSubmitButtonText] = useState('Enviar')
	const [sending, setSending] = useState(false)
	const [sendSuccess, setSendSuccess] = useState(false)
	const [sendFailure, setSendFailure] = useState(false)
	const [submitDisabled, setSubmitDisabled] = useState(false)

	const [products, setProducts] = useState(targetProduct ? [targetProduct] : [])

	function handleSubmit(e) {
		e.preventDefault()
		setSubmitButtonText('Enviando...')
		setSending(true)
		setSubmitDisabled(true)

		sendEmail({
			name,
			email,
			firm,
			phone,
			subject,
			message,
			products,
		})
			.then((res) => {
				console.log(res)
				if (!res.ok) {
					throw res
				}

				setSubmitButtonText('Mensagem enviada com sucesso')
				setSubmitDisabled(true)
				setSending(false)
				setSendSuccess(true)
				setSendFailure(false)
			})
			.catch((e) => {
				console.log(e)
				setSubmitButtonText(
					'Falha ao enviar a mensagem, tente as outras formas de contato'
				)
				setSubmitDisabled(true)
				setSending(false)
				setSendSuccess(false)
				setSendFailure(true)
			})
	}

	function handleProductFieldChange(value, index, field) {
		setProducts(
			products.map((product, idx) => {
				if (idx !== index) {
					return product
				}

				product[field] = value
				return product
			})
		)
	}

	function handleCreateProduct(e) {
		e.preventDefault()
		setProducts([
			...products,
			{
				title: '',
				sku: '',
				quantity: 1,
			},
		])
	}

	function handleProductDelete(product) {
		const _products = products.filter((_product) => _product !== product)
		setProducts(_products)
	}

	return (
		<form className="uk-grid-small" onSubmit={handleSubmit} data-uk-grid>
			<div className="uk-width-1-1@s uk-width-1-2@m">
				<input
					className="uk-input"
					type="text"
					placeholder="Insira seu nome"
					required
					onChange={(e) => setName(e.target.value)}
				/>
			</div>
			<div className="uk-width-1-1@s uk-width-1-2@m">
				<input
					className="uk-input"
					type="email"
					placeholder="Insira seu e-mail"
					required
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
			<div className="uk-width-1-1@s uk-width-1-2@m">
				<input
					className="uk-input"
					type="text"
					placeholder="Insira o nome ou site da sua empresa"
					required
					onChange={(e) => setFirm(e.target.value)}
				/>
			</div>
			<div className="uk-width-1-1@s uk-width-1-2@m">
				<MaskedInput
					mask={[
						'(',
						/[1-9]/,
						/\d/,
						')',
						' ',
						/\d/,
						/\d/,
						/\d/,
						/\d/,
						'-',
						/\d/,
						/\d/,
						/\d/,
						/\d/,
						/\d?/,
					]}
					className="uk-input"
					placeholder="Insira um número de telefone para contato"
					guide={false}
					required
					onChange={(e) => setPhone(e.target.value)}
				/>
			</div>
			<div className="uk-width-1-1">
				<ul data-uk-accordion>
					<li className="uk-open">
						<a className="uk-accordion-title" href="#">
							Produto(s)
						</a>
						<div className="uk-accordion-content">
							{products.map((product, index) => (
								<div
									key={`contact-form-product-${index}`}
									className="uk-grid-small"
									data-uk-grid
								>
									<div className="uk-width-1-1@s uk-width-expand">
										<input
											className="uk-input"
											type="text"
											placeholder="Título"
											value={product.title}
											required
											onChange={(e) =>
												handleProductFieldChange(e.target.value, index, 'title')
											}
										/>
									</div>
									<div className="uk-width-2-3@s uk-width-1-3@m">
										<input
											className="uk-input"
											type="text"
											placeholder="REF (código do produto)"
											value={product.sku}
											required
											onChange={(e) =>
												handleProductFieldChange(e.target.value, index, 'sku')
											}
										/>
									</div>
									<div className="uk-width-1-3@s uk-width-1-6@m">
										<select
											className="uk-select"
											defaultValue={product.quantity}
											onChange={(e) =>
												handleProductFieldChange(
													e.target.value,
													index,
													'quantity'
												)
											}
											required
										>
											{Array.from(Array(10).keys()).map((n) => (
												<option
													key={`opt-${index}-${n}`}
													defaultValue={n + 1}
													defaultChecked={product.quantity === n + 1}
												>
													{`x${n + 1}`}
												</option>
											))}
										</select>
									</div>
									<button
										type="button"
										data-uk-close
										onClick={() => handleProductDelete(product)}
									></button>
								</div>
							))}

							<div className="uk-grid-small" data-uk-grid>
								<div className="uk-width-expand"></div>
								<div className="uk-width-auto">
									<button
										className="uk-button uk-button-secondary"
										onClick={handleCreateProduct}
									>
										Adicionar Produto
									</button>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div className="uk-width-1-1">
				<input
					className="uk-input"
					type="text"
					placeholder="Insira o assunto"
					required
					onChange={(e) => setSubject(e.target.value)}
				/>
			</div>
			<div className="uk-width-1-1">
				<textarea
					className="uk-textarea"
					placeholder="Digite sua mensagem"
					rows="5"
					required
					onChange={(e) => setMessage(e.target.value)}
				></textarea>
			</div>
			<div className="uk-width-1-1">
				<input
					className="uk-button uk-button-primary"
					type="submit"
					id="contactSubmit"
					value={submitButtonText}
					disabled={submitDisabled}
				/>
				{sending && <StatusIconSending />}
				{!sending && sendSuccess && <StatusIconSuccess />}
				{!sending && sendFailure && <StatusIconFailed />}
			</div>
		</form>
	)
}

export default ContactForm
